.SingleNewsBlog {
  width: 100%;

}
.SingleNewsBlogMain {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 0px 60px;
  margin: 0 auto;
  margin-top: 5rem;
}

.socialIcons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 6px 0;
  color: white;
  margin: 5px;
}

div.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.icons {
  font-size: 1.2rem;
}
.twitter {
  background-color: rgb(18, 192, 223);
}
.facebook {
  background-color: rgb(42, 73, 177);
}
.instagram {
  background: linear-gradient(blue 5%, rgb(231, 10, 183));
}

.mainHeader {
  margin: 1rem 0;
  width: 60%;
  font-size: 28px;
  /* font-size: clamp(1rem, 2vw, 1.2rem); */
}

.subHeader {
  width: 60%;
  font-size: 16px;
  margin-bottom: 1rem;
font-weight: 400;

}

.blogger {
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-column-gap: 0.5rem;
  /* margin-top: 1rem; */
}

.bloggerImage img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  object-fit: cover;
}

.bloggerInfo {
  /* display: grid; */
  /* padding-top: 50%; */
  color: rgb(167, 158, 158);
  font-size: 0.6rem;
  align-self: center;
}

/* main- blog-info-secton */
.mainBlog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainBlogImageContainer {
  width: 100%;
  height: 25rem;
  margin: 2rem 0px;
}

.mainBlogImage {
  width: 100%;
  height: 100%;
}

.mainBlogContent h2 {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
}
.mainBlogContent img {
  width: 300px;
  height: 300px;
}

.articles article {
  display: grid;
  width: 100%;
  grid-template-columns: 8rem 1fr;
  height: 10rem;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.articles .header {
  text-align: center;
  -webkit-text-stroke-width: 1.2px;
  font-size: clamp(1.5rem, 2vw, 2rem);
  margin: 1.5rem 0;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #694a98;
  text-transform: uppercase;
}

/* .articles h2 {
    font-size: 0.8rem;
  } */

.articleImage {
  width: 8rem;
  height: 8rem;
}
.articleImage img {
  max-width: 100%;
  height: 10rem;
  object-fit: cover;
  vertical-align: middle;
}

.articleContent {
  /* display: grid; */
  padding: 0.5rem;
  height: 10rem;
}

.articleContent h1 {
  font-size: clamp(0.7rem, 2vw, 1.2rem);
  text-decoration: underline;
}

.articleContent h2 {
  color: #000;
  margin-top: 0.3rem;
  font-size: clamp(0.6rem, 2vw, 1rem);
}

/*======= comments ===== */
.commentHeader {
  text-align: center;
  font-size: 0.8rem;
}

.commentContent {
  width: 100%;
  resize: none;
  padding: 10px;
  border: solid 1px #ccc;
  outline: none;
}

.commentContent:focus {
  border: solid 1px #bbb;
}

.commentBoxContainer {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 7px;
}

.commentBoxContainer img {
  width: 100%;
  vertical-align: middle;
}
.post {
  grid-column: 2/ -1;
  border-radius: 2px;
  padding: 5px;
  color: white;
  background-color: #694a98;
  outline: none;
  border: none;
  font-size: 0.8rem;
  font-weight: 300;
  cursor: pointer;
}

.post:hover {
  background-color: #1b95c8;
}
.person {
  font-size: 1.5rem;
  background-color: #eee;
  padding: 10px;
  margin-right: 15px;
}

.commentsContainer {
  display: grid;
  grid-template-columns: 100px 1fr;
  border: solid 1px #eee;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  margin: 2rem 0;
}

.comments {
  margin-bottom: 4rem;
}

.commentsContentContainer {
  display: grid;
  padding: 5px;
}
.commentsImage {
  width: 100%;
  vertical-align: middle;
  object-fit: cover;
  height: 100%;
}
.comments-id small {
  color: rgb(167, 158, 158);
}
.commentsId,
.commentsContent {
  margin: 0;
  font-size: 0.6rem;
}
.commentsId {
  font-size: 0.7rem;
}

.reply,
.share {
  color: rgb(117, 116, 116);
  text-decoration: none;
  display: inline-block;
  /* font-weight: 300; */
  font-size: 0.6rem;
}

.spinner {
  width: 30px;
  margin: 0 auto;
  padding: 2rem 0;
}

/* Responsive styles */

@media (max-width: 768px) {
  .SingleNewsBlogMain {
    padding: 0px 20px;
  }
}

@media (max-width: 520px) {
  .SingleNewsBlogMain {
    width: 100%;
    padding: 0px 30px;
  }

  .mainHeader {
    width: 100%;
    font-size: 18px;
  }

  .subHeader {
    width: 100%;
    font-size: 16px;
  }

  .blogger {
    grid-template-columns: auto 1fr;
  }

  /* main- blog-info-secton */
.mainBlog {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* display: none; */
  }
  
  .mainBlogImageContainer {
  width: 300px;
  height: 300px;
  margin: 2rem 0px;
  /* display: none; */
  }
  
  .mainBlogImage {
	width: 100%;
	height: 100%;
   }
  
  
  
    .mainBlogContent img {
  
   width: 300px;
   height: 300px;
	
  }

  .articles {

	/* display: none; */
  }
  
  .articles article {
	display: grid;
	width: 100%;
	grid-template-columns: 8rem 1fr;
	height: 10rem;
	box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
	margin-bottom: 2rem;
  }
  
  .articles .header {
	text-align: center;
	-webkit-text-stroke-width: 1.2px;
	font-size: clamp(1.5rem, 2vw, 2rem);
	margin: 1.5rem 0;
	font-weight: 900;
	-webkit-text-fill-color: white;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #694a98;
	text-transform: uppercase;
  }
  
 
  
  .articleImage {
	width: 8rem;
	height: 8rem;
  }
  .articleImage img {
	max-width: 100%;
	height: 10rem;
	object-fit: cover;
	vertical-align: middle;
  }
  
  .articleContent {
	/* display: grid; */
	padding: 0.5rem;
	height: 10rem;
	display: none;
  }
  
  .articleContent h1 {
	font-size: clamp(0.7rem, 2vw, 1.2rem);
	text-decoration: underline;
  }
  
  .articleContent h2 {
	color: #000;
	margin-top: 0.3rem;
	font-size: clamp(0.6rem, 2vw, 1rem);
  }
}
