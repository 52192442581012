.form-loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    justify-content: center;
    align-items: center;
}

.form-loader .content{
    flex: 1;
    text-align: center;
}