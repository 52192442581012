.hero {
	width: 100%;
	margin-top: -75px;
	background: url("/src/assets/images/news_reading.jpg");
	background-position: center;
	background-size: cover;
	background-color: #cbabcd;
	background-blend-mode: overlay;
	background-repeat: no-repeat;
	height: 30rem;
	align-items: center;
	line-height: 30rem;
}

.hero h1 {
	text-align: center;
	color: #fff;
	font-size: 5rem;
}

.news,
.articles .header {
	text-align: center;
	-webkit-text-stroke-width: 1.2px;
	font-size: clamp(1.5rem, 2vw, 2rem);
	margin: 1.5rem 0;
	font-weight: 900;
	-webkit-text-fill-color: white;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #694a98;
	text-transform: uppercase;
}

.NewsBlog {
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.NewsBlog a h2 {
	color: #000;
}

.NewsBlog a h1 {
	text-decoration: underline;
}

.featuredNews {
	margin-bottom: 2rem;
}

.newsCard {
	width: 30rem;
	height: 20rem;
	padding: 0.5rem;
}

.newsCardCont {
	box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
	height: 20rem;
}
.imageCard {
	width: 100%;
	height: 15rem;
	/* margin-bottom: 8rem; */
}

.imageCard img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.newsCard h1 {
	padding: 1rem;
	font-size: clamp(0.7rem, 2vw, 1rem);
}

.sort {
	margin-bottom: 2rem;
}
.sort h1 {
	text-align: center;
}

.BtnContainer {
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 1rem;
}

.BtnContainer button {
	width: 100%;
	padding: 0.3rem;
	border-radius: 2px;
	border: solid 1px #694a98;
	border-bottom-color: #1b95c8;
	outline: none;
	font-weight: 600;
	margin: 0.5rem 0;
	cursor: pointer;
}

.BtnContainer button:hover,
.BtnContainer button:active {
	background: linear-gradient(#694a98, #1b95c8);
	color: #fff;
}

.articles article {
	display: grid;
	/* grid-template-columns: 15rem 1fr; */
	grid-template-columns: 8rem 1fr;
	height: 10rem;
	box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
	margin-bottom: 2rem;
}

/* .articles h2 {
  font-size: 0.8rem;
} */

.articleImage {
	height: 10rem;
	width: 8rem;
}
.articleImage img {
	max-width: 100%;
	height: 10rem;
	object-fit: cover;
	vertical-align: middle;
}

.articleContent {
	padding: 0.5rem;
	height: 10rem;
}

.articleContent h1 {
	font-size: clamp(0.7rem, 2vw, 1.2rem);
}

.articleContent h2 {
	margin-top: 0.3rem;
	font-size: clamp(0.6rem, 2vw, 1rem);
}

.blogger {
	display: grid;
	grid-template-columns: 48px 1fr;
	grid-column-gap: 0.5rem;
	/* margin-top: 1rem; */
}

.bloggerImage {
	/* width: 48px;
  border-radius: 50%;
  height: 48px; */
	margin-top: 20%;
	align-self: flex-end;
}

.bloggerImage img {
	height: 38px;
	width: 38px;
	/* width: 100%; */
	border-radius: 50%;
	object-fit: cover;
}

.bloggerInfo {
	/* display: grid; */
	/* padding-top: 50%; */
	color: rgb(167, 158, 158);
	font-size: 0.6rem;
	align-self: center;
}

.socialIcons a {
	display: inline-block;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	padding: 6px 0;
	color: white;
	margin: 5px;
}

div.socialIcons {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem;
}

.icons {
	font-size: 1.2rem;
}
.twitter {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(18, 192, 223);
}
.facebook {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(42, 73, 177);
}
.instagram {
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(blue 5%, rgb(231, 10, 183));
}

.spinner {
	width: 30px;
	margin: 0 auto;
	padding: 2rem 0;
}

@media (min-width: 591px) {
	.NewsBlog {
		width: 100%;
	}
	.social {
		position: absolute;
		top: 3rem;
		left: -50px;
	}
	.socialIconContainer {
		/* position: sticky; */
		top: 2rem;
	}

	div.socialIcons {
		margin: 0;
		display: flex;
		flex-direction: column;
	}
}
